@font-face {
    font-family: 'SF Mono Regular';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Regular'), url('SFMonoRegular.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Regular Italic'), url('SFMonoRegularItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Light';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Light'), url('SFMonoLight.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Light Italic'), url('SFMonoLightItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Medium';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Medium'), url('SFMonoMedium.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Medium Italic'), url('SFMonoMediumItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Semibold'), url('SFMonoSemibold.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Semibold Italic'), url('SFMonoSemiboldItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Bold';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Bold'), url('SFMonoBold.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Bold Italic'), url('SFMonoBoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Heavy'), url('SFMonoHeavy.woff') format('woff');
}

@font-face {
    font-family: 'SF Mono Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Mono Heavy Italic'), url('SFMonoHeavyItalic.woff') format('woff');
}