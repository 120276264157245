.profilePic{
    z-index: 2;
}

.profilePicDiv:after {
    content: '';
    position: absolute;
    border: 2px solid white;
    width: 250px;
    height: 250px;
    margin-left: 50px;
    margin-top: 50px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 1;
}

