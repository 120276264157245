/*Tailwind base*/

@tailwind base;
@tailwind components;
@tailwind utilities;

/*Fonts import*/

@import url('./fonts/SFMono.css');
h1 {
    font-family: SF Mono Regular;
    color: #5ae7c8;
}

p{
    font-family: SF Mono Regular;
}

.App-header {
    background-color: #0a192f;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
}

.section {
    width: 100%;
    height: 100vh;
}

.job-section {
    width: 100%;
   
}

/*Mansory cards*/

@layer utilities {
    @variants responsive {
        .masonry {
            column-gap: 1.5em;
            column-count: 1;
        }
        .masonry-sm {
            column-gap: 1.5em;
            column-count: 2;
        }
        .masonry-md {
            column-gap: 1.5em 1.5em;
            column-count: 3;
        }
        .break-inside {
            margin-bottom: 0.5em;
            break-inside: avoid;
        }

    }
}